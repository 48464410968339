<template>
  <div class="goals">
    <div class="goals__header">
      {{ headerTitle }}
    </div>
    <DataByPeriod type="goals" s />
    <br />
    <div v-if="colorsConfig.show_logo && showLogos" class="bet-logo">
      <BetLogo :url-logo="colorsConfig.main_logo" :url-link="colorsConfig.url_base" />
    </div>
    <div class="corners__per-minutes">
      <CornersAndGoalsPerMinute type="goals" :key="perMinuteKey" />
    </div>
    <br />
    <div v-if="colorsConfig.show_logo && showLogos" class="bet-logo">
      <BetLogo :url-logo="colorsConfig.main_logo" :url-link="colorsConfig.url_base" />
    </div>
    <div v-if="!selectedGame.isCup" class="goals__last-five-games">
      <LastFiveGames :teams-data="teamsData" is-goals />
    </div>

    <div v-if="selectedGame.hasBets && areBetsVisible" class="betpergoas-bets">
      <BetBets :game-id="selectedGame.game_id" :type="3" />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

export default {
  name: 'Goals',
  components: {
    CornersAndGoalsPerMinute: () => import('@/components/Sections/GolTipster-v2/General/CornersAndGoalsPerMinute'),
    BetBets: () => import('@/components/Sections/GolTipster-v2/General/BetBets'),
    DataByPeriod: () => import('@/components/Sections/GolTipster-v2/General/DataByPeriod'),
    LastFiveGames: () => import('@/components/Sections/GolTipster-v2/General/LastFiveGames'),
    BetLogo: () => import('@/components/Elements/BetLogo'),
  },
  props: {
    isCornersGoalsMobile: {
      type: Boolean,
      default: false,
    },
    showLogos: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      perMinuteKey: 400,
      goalsRows: [
        {
          text: '',
          gameProp: 'rival_logo',
          type: 0,
        },
        {
          text: 'Resultado:',
          gameProp: ['score', 'first_time_score', 'second_time_score'],
          type: 2,
        },
        {
          text: 'Primero en anotar:',
          textMobile: '1ro. en anotar:',
          gameProp: ['first_goal', 'first_time_first_goal', 'second_time_first_goal'],
          type: 1,
          isBoolean: true,
        },
        {
          text: 'Anotó gol:',
          gameProp: ['scored_goal', 'first_time_scored_goal', 'second_time_scored_goal'],
          type: 1,
          isBoolean: true,
        },
      ],
      areBetsVisible: false,
    };
  },
  computed: {
    ...mapState('betsGeneral', ['selectedGame', 'filterGamePeriod', 'rightBetComponent', 'refreshIntervalCounter']),
    ...mapState('profileGeneral', ['colorsConfig']),
    ...mapGetters('betsGeneral', ['lastFiveGames']),
    ...mapState(['displayWidth']),
    headerTitle() {
      return this.isCornersGoalsMobile ? 'Goles por tiempos' : 'Goles Totales';
    },
    isLastFiveMobile() {
      return this.displayWidth < 940;
    },
    teamsData() {
      return {
        homeTeam: {
          logo: this.selectedGame.homeTeam.logo,
          name: this.selectedGame.homeTeam.colloquial_name,
          tableRows: this.getTableRows(
            this.lastFiveGames.homeTeam,
            this.selectedGame.homeTeam.team_id,
            this.isLastFiveMobile,
          ),
        },
        awayTeam: {
          logo: this.selectedGame.awayTeam.logo,
          name: this.selectedGame.awayTeam.colloquial_name,
          tableRows: this.getTableRows(
            this.lastFiveGames.awayTeam,
            this.selectedGame.awayTeam.team_id,
            this.isLastFiveMobile,
          ),
        },
      };
    },
  },
  watch: {
    rightBetComponent(newValue) {
      if (newValue === 'Goals') {
        this.perMinuteKey += 1;
      }
    },
    selectedGame() {
      this.setAreBetVisible();
    },
    refreshIntervalCounter: {
      immediate: true,
      handler() {
        this.setAreBetVisible();
      },
    },
  },
  methods: {
    setAreBetVisible() {
      const betSectionsLimit = Date.now() + 60 * 1000;
      const gameDateTime = new Date(this.selectedGame.date_time_utc).valueOf();
      this.areBetsVisible = gameDateTime >= betSectionsLimit;
    },
    getTableRows(games, teamId, isMobile) {
      if (!games || !teamId) {
        return [];
      }
      return this.goalsRows.reduce((accumulator, row) => {
        const rowAux = {
          type: row.type,
          values: [isMobile && row.textMobile ? row.textMobile : row.text],
        };
        games.forEach(game => {
          if (row.type !== 2) {
            rowAux.values.push(
              row.isBoolean
                ? game[this.getGameProp(row.gameProp)]
                  ? 'Sí'
                  : 'No'
                : game[this.getGameProp(row.gameProp)],
            );
          } else {
            const teamScorePosition = teamId === game.home_team_id ? 0 : 1;
            const score = game[this.getGameProp(row.gameProp)];
            rowAux.values.push([
              {
                score: score[0],
                isTeamScore: teamScorePosition === 0,
              },
              {
                score: score[1],
                isTeamScore: teamScorePosition === 1,
              },
            ]);
          }
        });
        accumulator.push(rowAux);
        return accumulator;
      }, []);
    },
    getGameProp(gameProp) {
      return Array.isArray(gameProp) ? gameProp[this.filterGamePeriod] : gameProp;
    },
  },
};
</script>

<style scoped lang="scss">
.goals {
  padding: 0 0.5rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  &__header {
    border-radius: 7px;
    background-color: #132839;
    font-family: Roboto-Regular, sans-serif;
    font-size: 1.25rem;
    font-weight: 500;
    color: white;
    padding: 0.25rem;
    margin: 3rem 0 1rem;
  }
}

.betperesult-bets {
  width: 100%;
  height: auto;
  display: inline-flex;
  justify-content: center;
}

.bet-logo {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 1.5rem;
}
</style>
